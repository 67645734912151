<template>
  <div class="input-screen-page-wrapper" style="width: 100%; height: 100%">
    <!-- Menu options -->
    <v-row no-gutters>
      <attribute-filter-panel
        title-localisation="attributes.filters.filterByAttributes"
        filter-count-localisation="attributes.filters.numApplied"
        enable-hierarchy-filters
        :enable-non-attribute-filters="true"
        :enable-attribute-filters="true"
        :filter-rules="retailAttributesFilter"
        @attributeFilterChange="setFilterRules"
      />
      <v-alert :value="invalidFilters" color="error" icon="warning" outlined>
        {{ $t(`inputs.invalidFilterWarning`) }}
      </v-alert>
      <v-alert :value="mainBannerFilterWarning" color="info" icon="info" outlined>
        {{ $t(`inputs.mainBannerFilterWarning`) }}
      </v-alert>
    </v-row>

    <div class="inputs-actions">
      <v-text-field
        v-model="searchString"
        outlined
        dense
        class="inputs-actions__search"
        :disabled="dataLoading"
        :label="$t('actions.search')"
        single-line
        append-icon="mdi-magnify"
        @input="debouncedFilterChange"
      />

      <v-autocomplete
        v-model="externalFilterSelection"
        :items="externalFilterOptions"
        class="inputs-actions__filter"
        outlined
        dense
        chips
        deletable-chips
        small-chips
        :label="$t('inputs.filterBarText')"
        :item-text="translateLabels"
        @input="externalFilterChanged"
      />

      <div class="inputs-actions__btn-container">
        <slot name="buttons" />

        <v-btn
          color="primary"
          small
          :loading="isExporting"
          class="inputs-actions__download grid-btn"
          depressed
          @click="downloadInputs"
        >
          <v-icon small>$export</v-icon>
          {{ $t('actions.download') }}
        </v-btn>
      </div>

      <v-btn
        class="inputs-actions__columns grid-btn"
        secondary
        small
        depressed
        @click="toggleSideBar('columns')"
      >
        {{ $t('inputs.columns') }}
        <v-icon class="material-icons-outlined ml-1 sidebar-toggle">view_column</v-icon>
      </v-btn>
    </div>

    <!-- TODO: ag-grid height -->
    <ag-grid-vue
      :style="{ width: '100%', height: `${dataTableHeight}px` }"
      class="ag-theme-inputsgrid"
      :side-bar="sideBar"
      :column-defs="allColumns"
      :grid-options="allGridOptions"
      :stop-editing-when-grid-loses-focus="true"
      :enable-range-selection="true"
      :is-external-filter-present="isExternalFilterPresent"
      :does-external-filter-pass="doesExternalFilterPass"
      @cell-value-changed="trackDiff"
      @grid-ready="onGridReady"
      @first-data-rendered="onFirstDataRendered"
      @sort-changed="collapseStoregroups"
      @modelUpdated="onModelUpdated"
    />

    <div class="inputs-footer">
      <div class="inputs-footer__row-count">
        <span class="message">Rows: {{ displayedRowCount }}</span>
      </div>
      <div class="inputs-footer__msg-container">
        <span v-if="notAllDataOnDisplay" class="message">{{
          $t('inputs.maxResultsShown', { limit: rowLimit })
        }}</span>
      </div>
      <div class="inputs-footer__btn-container">
        <v-btn
          class="grid-btn"
          secondary
          small
          :disabled="dataLoading || !editsExist"
          depressed
          @click="discardChanges"
          >{{ $t('inputs.cancel') }}</v-btn
        >

        <v-btn :disabled="!saveAllowed" small color="primary" depressed @click="handleSave">
          {{ $t('actions.save') }}
          <v-icon small>$import</v-icon>
        </v-btn>
      </div>
    </div>

    <unsaved-changes-dialog
      v-if="showUnsavedChangesDialog"
      :show-dialog="showUnsavedChangesDialog"
      :save-allowed="saveAllowed"
      @closedUnsavedChangesDialog="handleUnsavedModalDecision"
    />

    <delete-hierarchy-level-dialog
      :key="deleteDialogToggle"
      :is-open="isHierarchyDeleteDialogOpen"
      :level="level"
      :fetch-hierarchy="setupHierarchy"
      @childHierarchyDeleted="closeHierarchyDeleteDialog"
    />

    <add-new-hierarchy-level-dialog
      v-if="!loadingHierarchy && isHierarchyDialogOpen"
      :is-open="isHierarchyDialogOpen"
      :level="level"
      :mode="mode"
      :ignore-attribute-fetch="true"
      :fetch-hierarchy="setupHierarchy"
      @closeHierarchyDialog="closeHierarchyDialog"
    />
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import { mapActions, mapState, mapGetters } from 'vuex';
import axios from 'axios';
import to from 'await-to-js';
import {
  cloneDeep,
  get,
  keyBy,
  isEmpty,
  each,
  merge,
  groupBy,
  size,
  isNil,
  isNull,
  set,
  setWith,
  reduce,
  isEqual,
  debounce,
  has,
  isUndefined,
  map,
  pick,
  sortBy,
} from 'lodash';
import agGridChevronIcon from '../../../components/ag-grid-cell-renderers/ag-grid-chevron-icon.vue';
import agMenuHeader from '../../../components/ag-grid-cell-renderers/ag-menu-header.vue';
import dialogModes from '@enums/dialog-modes';
import { categoryLevel, pricingGroupLevel, architectureGroupLevel } from '@enums/hierarchy';
import featureFlagsMixin from '../../../mixins/featureFlags';
import inputScreensTableMixin from '../../../mixins/inputScreensTable';
import { useZones, displayArchitectureSubGroupSplittingAttributes } from '@enums/feature-flags';
import { cancel, save } from '@enums/inputs/unsaved-changes-options';
import { formatFilters } from '../../../store/utils/filters';
import customTooltip from '../custom-tooltip.vue';

const hierarchyLevels = {
  categoryLevel,
  pricingGroupLevel,
  architectureGroupLevel,
};

export default {
  name: 'InputsGrid',

  components: {
    AgGridVue,
    // register vue components
    // eslint thinks that it's not used, but headerComponent points to it
    // eslint-disable-next-line vue/no-unused-components
    agMenuHeader,
    // eslint-disable-next-line vue/no-unused-components
    agGridChevronIcon,
    // eslint-disable-next-line vue/no-unused-components
    customTooltip,
  },

  mixins: [featureFlagsMixin, inputScreensTableMixin],

  props: {
    additionalColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    // Needed to add more columns to the product column group
    additionalProductColumns: {
      type: Array,
      default: () => [],
    },
    // Function for saving and persisting all edits
    saveChanges: {
      type: Function,
      required: true,
      default: () => console.error('Prop failed to bind for saveChanges'),
    },
    // Any functions that need to run after save but do not block editing in the grid
    // This is highly speculative and should be revisited before release.
    postSaveAction: {
      type: Function,
      default: x => x,
    },
    additionalGridOptions: {
      type: Object,
    },
    editableHierarchies: {
      type: Boolean,
    },
    routeSuffix: {
      type: String,
      default: '',
    },
    isSaveable: {
      type: Boolean,
      default: true,
    },
    isExporting: {
      type: Boolean,
      default: false,
    },
    exportAction: {
      type: Function,
    },
    columnValidations: {
      type: Object,
    },
    additionalDependentColumns: {
      type: Object,
    },
  },

  data() {
    return {
      rootTableSelector: 'div.ag-theme-inputsgrid',
      deleteDialogToggle: true,
      unsavedChangesModalOutcome: null,
      // used to await actions until grid is actually initialised.
      gridReadyPromise: null,
      storeGroupsKeyToDescription: {},
      productKeyIsString: false,
      hierarchy: [],
      hierarchyByParent: {},
      storegroups: [],
      pgsToRecalc: [],
      agsToRecalc: [],
      mainTsgKey: null,
      filteredRows: {},
      searchString: '',
      dependentColumns: {
        pricingGroup: ['architectureGroup'],
        architectureGroup: ['pricingGroup'],
      },
      storegroupsByKey: {},
      invalidRows: {},
      selectedFilterParams: {},
      selectedStoregroup: null,
      storegroupFilterKey: true,
      unitFilterKey: true,
      invalidFilters: false,
      mainBannerFilterWarning: false,
      gridIsSaveable: true,
      gridHasRenderedData: false,
      dataLoading: false,
      storegroupAttributeKey: null,
      showUnsavedChangesDialog: false,
      // hierarchy modals
      level: null,
      mode: dialogModes.create,
      isHierarchyDialogOpen: false,
      isHierarchyDeleteDialogOpen: false,
      // Keyed by row id
      transactionsToApply: {},
      // products keyed by sg
      productsByStoreGroupKey: {},
      expandedSgProducts: [],
      numInputsReturned: 0,
      isInMainBannerAttributeKey: '',

      gridApi: null,
      lastExpandedRowKey: null,
      currentStateDiff: {},
      originalValueTracker: {},
      displayedRowCount: 0,

      externalFilterSelection: null,
      externalFilterOptions: [
        { text: 'inputs.filterOptions.edited', value: 'editedRows' },
        { text: 'inputs.filterOptions.invalid', value: 'invalidRows' },
      ],

      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: '',
            labelKey: 'columns',
            iconKey: 'close',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
            },
          },
        ],
        hiddenByDefault: true,
      },

      gridOptions: {
        singleClickEdit: true,
        stopEditingWhenCellsLoseFocus: true,
        enableFillHandle: true,
        fillHandleDirection: 'y',
        enableRangeSelection: true,
        tooltipShowDelay: 200,
        accentedSort: true,
        treeData: true,
        getDataPath: data => {
          // Top-level row for main banner products which can be expanded
          // Also applies to unique product rows when not using zones
          if (this.isMainbanner(data.toolStoreGroupKey)) {
            return [`${data.productKey}`];
          }
          // Inner-level row for products that are part of other store-groups
          // but are also present in main banner
          if (this.isInMainbanner(data.productKey)) {
            return [`${data.productKey}`, data.toolStoreGroupKey];
          }
          // Top-level row for local products, which are not present in main banner
          return [`${data.productKey}-${data.toolStoreGroupKey}`];
        },
        isExternalFilterPresent() {
          return true;
        },
        getMainMenuItems: this.getColumnMenuItems,
        getContextMenuItems: this.getContextMenuItems,
        groupDefaultExpanded: 1,
        // PRICE-3579: This exports the cell value as a string. This keeps the format constant between copying
        // values from inside the cell or copying the entire cell. However, export will export a localised string.
        // This should be reassessed when we swap to ag-grid export in PRICE-3579.
        useValueFormatterForExport: true,
        autoGroupColumnDef: {
          headerName: '',
          pinned: 'left',
          // prevent ag-grid chevron from displaying
          cellStyle: { border: 'none', display: 'none' },
          maxWidth: 0,
          filter: false,
          floatingFilter: false,
          suppressColumnsToolPanel: true,
          suppressNavigable: true,
          suppressFillHandle: true,
          cellRendererParams: {
            suppressCount: true,
            innerRenderer: () => {
              return '';
            },
          },
        },
        defaultColDef: {
          resizable: true,
          filter: 'agMultiColumnFilter',
          width: 100,
          // this is needed to avoid issue when multiple competitors are trying to get fit into one screen
          // https://github.com/owg-digital/rtls-pricing/pull/100
          minWidth: 100,
          flex: 1,
          menuTabs: ['filterMenuTab', 'generalMenuTab'],
          suppressMovable: true,
          required: true,
          tooltipValueGetter: this.tooltipValueGetter,
          tooltipComponent: 'customTooltip',
          cellClass: this.getCellKey,
          cellClassRules: {
            'diff-background': this.hasDiff,
            'error-background': this.hasInvalidValue,
          },
        },
        getRowId: this.getKey,
        headerHeight: 27,
        overlayNoRowsTemplate: `<span class="ag-overlay-loading-center">${this.$t(
          'grid.applyFilter'
        )}</span>`,
        overlayLoadingTemplate: `<span class="ag-overlay-loading-center">${this.$t(
          'grid.loading'
        )}</span>`,
      },
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('filters', ['retailAttributesFilter']),
    ...mapState('attributes', ['attributeMetadata', 'busyImportingAttributes']),
    ...mapState('clientConfig', ['inputsPages', 'exportConfigs', 'tooldata']),
    ...mapState('hierarchy', {
      loadingHierarchy: 'loading',
      busyImportingGroup: 'busyImportingGroup',
    }),
    ...mapGetters('context', ['isPricingSpecialist']),
    ...mapGetters('hierarchy', [
      'getHierarchyParentId',
      'getArchitectureSubGroupSplittingAttributes',
    ]),

    noInvalidRows() {
      return isEmpty(this.invalidRows);
    },

    allColumnValidations() {
      // Hierarchy update function declared here for simplicity, can move if these get bigger.
      const bothHierarchiesAssigned = {
        message: 'hierarchyMismatch',
        test: ({ node } = {}) => {
          return this.checkConsistentPgAg(node);
        },
      };

      const allValidations = {
        ...this.columnValidations,
        architectureGroup: {
          bothHierarchiesAssigned,
        },
        pricingGroup: {
          bothHierarchiesAssigned,
        },
      };

      return allValidations;
    },

    rowLimit() {
      return this.inputsPages.productLimit;
    },

    notAllDataOnDisplay() {
      return this.numInputsReturned >= this.rowLimit;
    },

    allGridOptions() {
      return merge({}, this.gridOptions, this.additionalGridOptions);
    },

    editsExist() {
      return size(this.currentStateDiff);
    },

    saveAllowed() {
      const saveAllowed =
        this.editsExist && this.gridIsSaveable && this.noInvalidRows && this.isSaveable;

      return saveAllowed;
    },

    zonesEnabled() {
      return this.isFeatureFlagEnabled(useZones);
    },

    isAttributeEditor() {
      return this.routeSuffix.includes('attributes');
    },

    architectureSubGroupSplittingAttributesEnabled() {
      return (
        this.isFeatureFlagEnabled(displayArchitectureSubGroupSplittingAttributes) &&
        this.isAttributeEditor
      );
    },

    mainbannerGroupedView() {
      if (!(this.selectedStoregroup && this.mainTsgKey)) return;

      return this.selectedStoregroup.toolStoreGroupKey === this.mainTsgKey;
    },

    hierarchyIdToNameMap() {
      // This is used to provide a key/id mapping for the hierarhy columns in the grid
      const res = reduce(
        this.hierarchy,
        (acc, hier) => {
          acc[hier.levelEntryKey] = hier.levelEntryDescription;
          return acc;
        },
        {
          // This sets the value for unset hierarchies, otherwise we get a blank cell.
          null: '-',
        }
      );

      return res;
    },

    initialHeaders() {
      const getHierarchyChildrenHeaders = () => {
        const children = [
          {
            field: `hierarchy.${categoryLevel}.levelEntryDescription`,
            colId: 'category',
            menuTabs: ['filterMenuTab'],
            headerName: this.$t('pricing.category'),
            filter: 'agMultiColumnFilter',
            suppressMenu: false,
            editable: false,
            cellClass: 'bold-text',
            required: true,
          },
          {
            field: `pricingGroup`,
            colId: 'pricingGroup',
            suppressFillHandle: true,
            headerName: this.$t('pricing.pricingGroup'),
            menuTabs: ['filterMenuTab'],
            // refData doesn't handle custom sort
            comparator: this.hierarchySort,
            ...this.hierachyfilter(),
            ...(!this.editableHierarchies && { editable: false }),
            ...(this.editableHierarchies && {
              cellEditor: 'agRichSelectCellEditor',
              cellEditorParams: params => {
                const categoryId = params.data.hierarchy[2].id;
                const res = map(this.hierarchyByParent[categoryId], 'levelEntryKey');

                return {
                  values: [...res, null],
                  cellHeight: 20,
                  formatValue: value => {
                    const keyMappedToName = get(this.hierarchyIdToNameMap, value, value);

                    return isNil(value)
                      ? this.$t('attributes.actions.unassignPricingGroup')
                      : keyMappedToName;
                  },
                  searchDebounceDelay: 500,
                };
              },
              editable: params => {
                return (
                  this.editableHierarchies &&
                  (this.isMainbanner(params.data.toolStoreGroupKey) ||
                    this.hasIsInMainbannerAttributeSetToNo(params.data))
                );
              },
              headerComponent: 'agMenuHeader',
              headerComponentParams: {
                enableMenu: true,
                enableSorting: true,
                menuOptions: [
                  {
                    text: this.$t('attributes.createNewGroup'),
                    itemClick: this.openHierarchyCreateDialog,
                    params: { level: pricingGroupLevel },
                  },
                  {
                    text: this.$t('attributes.manageGroups'),
                    itemClick: this.openHierarchyUpdateDialog,
                    params: { level: pricingGroupLevel },
                  },
                  {
                    text: this.$t('attributes.editor.actions.deleteHierarchyLevel'),
                    itemClick: this.openHierarchyDeleteDialog,
                    params: { level: pricingGroupLevel },
                  },
                ],
              },
            }),
            suppressMenu: false,
            cellClass: 'bold-text',
            required: true,
            minWidth: 150,
            refData: this.hierarchyIdToNameMap,
          },
          {
            field: `architectureGroup`,
            colId: 'architectureGroup',
            suppressFillHandle: true,
            headerName: this.$t('pricing.architectureGroup'),
            menuTabs: ['filterMenuTab'],
            // refData doesn't handle custom sort
            comparator: this.hierarchySort,
            ...this.hierachyfilter(),
            ...(!this.editableHierarchies && { editable: false }),
            ...(this.editableHierarchies && {
              cellEditor: 'agRichSelectCellEditor',

              cellEditorParams: params => {
                const originalPg = params.data.pricingGroup;
                // Check if a pricing group update is present for this row
                // if there's an edited value, use that
                // if not, use the current pricing group value

                const editedPg = get(this.currentStateDiff, `${params.node.id}.pricingGroup`, null);

                const pgKey = editedPg || originalPg;
                const res = map(this.hierarchyByParent[pgKey], 'levelEntryKey');

                return {
                  values: [...res, null],
                  cellHeight: 20,
                  formatValue: value => {
                    const keyMappedToName = get(this.hierarchyIdToNameMap, value, value);

                    return isNil(value)
                      ? this.$t('attributes.actions.unassignArchitectureGroup')
                      : keyMappedToName;
                  },
                };
              },

              editable: params => {
                const pgAssigned = !isNil(params.data.pricingGroup);
                return (
                  this.editableHierarchies &&
                  pgAssigned &&
                  (this.isMainbanner(params.data.toolStoreGroupKey) ||
                    this.hasIsInMainbannerAttributeSetToNo(params.data))
                );
              },
              headerComponent: 'agMenuHeader',
              headerComponentParams: {
                enableMenu: true,
                enableSorting: true,
                menuOptions: [
                  {
                    text: this.$t('attributes.createNewGroup'),
                    itemClick: this.openHierarchyCreateDialog,
                    params: { level: architectureGroupLevel },
                  },
                  {
                    text: this.$t('attributes.manageGroups'),
                    itemClick: this.openHierarchyUpdateDialog,
                    params: { level: architectureGroupLevel },
                  },
                  {
                    text: this.$t('attributes.editor.actions.deleteHierarchyLevel'),
                    itemClick: this.openHierarchyDeleteDialog,
                    params: { level: architectureGroupLevel },
                  },
                ],
              },
            }),
            suppressMenu: false,
            cellClass: 'bold-text',
            required: true,
            minWidth: 150,
            refData: this.hierarchyIdToNameMap,
          },
        ];
        if (this.architectureSubGroupSplittingAttributesEnabled) {
          children.push({
            // generated data based on attribute values
            // requires both productKey and toolStoreGroupKey to exist in params.data
            field: 'architectureSubGroupSplittingAttributes',
            colId: 'architectureSubGroupSplittingAttributes',
            headerName: this.$t('pricing.architectureSubGroupSplittingAttributes'),
            valueGetter: params => this.formatSubGroupSplittingAttributes(params),
            menuTabs: ['filterMenuTab'],
            filter: 'agSetColumnFilter',
            suppressMenu: false,
            editable: false,
            cellClass: 'bold-text',
            required: true,
            minWidth: 150,
          });
        }
        return children;
      };

      return [
        {
          headerName: 'expansion-chevron',
          id: 'expansion-chevron',
          colId: 'expansion-chevron',
          field: 'expansion-chevron',
          hide: !this.zonesEnabled,
          suppressColumnsToolPanel: true,
          suppressMenu: true,
          pinned: 'left',
          maxWidth: 20,
          filter: false,
          cellRendererSelector: params =>
            this.isMainbanner(params.data.toolStoreGroupKey)
              ? {
                  component: 'agGridChevronIcon',
                }
              : null,
          cellRendererParams: params => {
            return {
              classes: ['expand-icon', 'clickable'],
              expandedStateIcon: 'mdi-chevron-down',
              collapsedStateIcon: 'mdi-chevron-right',
              isExpanded: () => this.lastExpandedRowKey === this.getKey(params.data),
              clickHandler: () => this.expandStoregroups(params),
            };
          },
        },
        {
          headerName: this.$t('inputs.defaultHeaders.product'),
          groupId: 'product-details',
          children: [
            {
              field: 'productKeyDisplay',
              colId: 'productKeyDisplay',
              headerName: this.$t('pricing.productKey'),
              menuTabs: ['filterMenuTab'],
              filter: 'agTextColumnFilter',
              suppressMenu: false,
              editable: false,
              pinned: 'left',
              cellClass: 'bold-text',
              required: true,
              ...(!this.productKeyIsString
                ? {
                    comparator: (valueA, valueB) => {
                      return Number(valueA) - Number(valueB);
                    },
                  }
                : {}),
            },
            {
              field: 'toolStoreGroupKey',
              colId: 'toolStoreGroupDescription',
              headerName: this.$t('pricing.toolStoreGroup'),
              menuTabs: ['filterMenuTab'],
              filter: 'agMultiColumnFilter',
              suppressMenu: false,
              editable: false,
              pinned: 'left',
              cellClass: 'bold-text',
              required: false,
              hide: !this.zonesEnabled,
              refData: this.storeGroupsKeyToDescription,
              comparator: this.storegroupSort,
              suppressColumnsToolPanel: !this.zonesEnabled,
            },
            {
              field: 'productName',
              colId: 'productName',
              headerName: this.$t('pricing.productDescription'),
              menuTabs: ['filterMenuTab'],
              filter: 'agTextColumnFilter',
              suppressMenu: false,
              editable: false,
              pinned: 'left',
              cellClass: 'bold-text',
              required: true,
              minWidth: 200,
            },
            ...this.additionalProductColumns,
          ],
        },
        {
          headerName: this.$t('inputs.defaultHeaders.hierarchy'),
          groupId: 'hierarchy',
          children: getHierarchyChildrenHeaders(),
        },
      ];
    },

    allColumns() {
      return [...this.initialHeaders, ...this.additionalColumns];
    },
  },

  mounted() {
    // Listen for the event
    this.$eventBus.$on('inputsUploaded', this.resolveGridPostSave);
  },
  beforeDestroy() {
    // Unsubscribe from the event to prevent memory leaks
    this.$eventBus.$off('inputsUploaded');
  },

  async created() {
    await this.init();

    if (this.architectureSubGroupSplittingAttributesEnabled) {
      await this.filterArchitectureSubGroupSplittingAttributesFromNewTab();
    }
  },

  methods: {
    ...mapActions('hierarchy', ['fetchHierarchy', 'initialiseNewPricingGroups']),
    ...mapActions('gridView', ['runEngineForSpecificPricingGroups']),
    ...mapActions('filters', ['setSelectedFilter']),
    ...mapActions('scenarioMetadata', ['fetchScenarioMetadata']),
    ...mapActions('architectureGroup', [
      'consumeNewTabOpenArchitectureSubGroupSplittingAttributes',
    ]),

    onModelUpdated(event) {
      this.displayedRowCount = event.api.getDisplayedRowCount();
    },

    formatSubGroupSplittingAttributes(params) {
      const subGroupSplittingAttributes = this.getArchitectureSubGroupSplittingAttributes({
        pricingGroupId: params.data.pricingGroup,
        architectureGroupId: params.data.architectureGroup,
      });
      const attributeKeys = subGroupSplittingAttributes.map(attr => attr.attributeKey);
      // match display format from getSubGroupsForProduct. sortBy and default js array.sort have slightly different behaviors
      // so even though we're not sorting by a key, we need sortBy here to match getSubGroupsForProduct / productKeySubGroupsMap
      const attributeDisplay = sortBy(attributeKeys).reduce((acc, attrKey) => {
        const attributeValue = get(params.data, attrKey);
        if (isNil(attributeValue)) {
          return acc;
        }
        return `${acc}-${attributeValue}`;
      }, '');
      // replace first -
      if (!isEmpty(attributeDisplay)) return attributeDisplay.replace('-', '');
      return '-';
    },

    async filterArchitectureSubGroupSplittingAttributesFromNewTab() {
      const architectureSubGroupDescription = await this.consumeNewTabOpenArchitectureSubGroupSplittingAttributes();
      if (!isNil(architectureSubGroupDescription)) {
        await this.setArchitectureSubGroupSplittingFilter(architectureSubGroupDescription);
      }
    },

    async setArchitectureSubGroupSplittingFilter(architectureSubGroupDescription) {
      const filterInstance = this.gridApi.getFilterInstance(
        'architectureSubGroupSplittingAttributes'
      );
      filterInstance.setModel({ values: [architectureSubGroupDescription] });
      this.gridApi.onFilterChanged();
    },

    debouncedFilterChange: debounce(function(newFilter) {
      this.gridApi.setQuickFilter(newFilter);
    }, 800),

    getContextMenuItems(params) {
      return params.defaultItems.filter(option => {
        return !['separator', 'export'].includes(option);
      });
    },

    filterRows(node) {
      const productFilterOptions = {
        editedRows: this.currentStateDiff,
        invalidRows: this.invalidRows,
      };
      const productFilter = productFilterOptions[this.externalFilterSelection];

      let res = productFilter[this.getKey(node.data)];
      if (res) return res;

      if (this.zonesEnabled) {
        const { productKey } = node.data;
        const storegroups = Object.keys(this.storegroupsByKey);

        const options = storegroups.map(toolStoreGroupKey =>
          this.getKey({ productKey, toolStoreGroupKey })
        );

        res = options.some(rowKey => productFilter[rowKey]);
      }

      return res;
    },

    handleUnsavedModalDecision(outcome) {
      // There is an unresolved promise already set when modal opens
      // This assigns the value, resolving it and letting the original function proceed.
      if (this.unsavedChangesModalOutcome) {
        this.unsavedChangesModalOutcome(outcome);
      }
    },

    translateLabels(item) {
      return this.$t(item.text);
    },

    checkIfProductKeyIsString() {
      const columnExportFormat = get(
        this.exportConfigs,
        'exportToExcel.columnFormatter.productKeyDisplay'
      );

      this.productKeyIsString = columnExportFormat === 'str';
    },

    toggleSideBar(selectedToolPanel) {
      const openToolPanel = this.gridApi.getOpenedToolPanel();
      // If the selected toolbar is not open, open it
      if (!openToolPanel || openToolPanel !== selectedToolPanel) {
        this.gridApi.setSideBarVisible(true);
        this.gridApi.openToolPanel(selectedToolPanel);
      } else if (openToolPanel === selectedToolPanel) {
        // If the selected tool panel is already open, close it
        this.gridApi.closeToolPanel();
        this.gridApi.setSideBarVisible(false);
      }
    },

    isMainbanner(toolStoreGroupKey) {
      // If not using zones, you only have mainbanner
      if (!this.zonesEnabled) return true;

      return toolStoreGroupKey === this.mainTsgKey;
    },

    isInMainbanner(productKey) {
      if (!this.mainTsgKey || !this.productsByStoreGroupKey[this.mainTsgKey]) return false;
      return this.productsByStoreGroupKey[this.mainTsgKey].has(productKey);
    },

    // Checks if product's data has isMainBannerAttribute set to 'No'.
    // Note:
    //    The important difference with the isInMainbanner is the code will work
    //    the same for both cases when there is no such attribute or the attribute
    //    is missing; and it relies on attribute's availability.
    hasIsInMainbannerAttributeSetToNo(productData) {
      return get(productData, this.isInMainBannerAttributeKey) === 'No';
    },

    async resolveEditsBeforeNavigation() {
      const preventNavigation = false;
      if (size(this.currentStateDiff)) {
        this.showUnsavedChangesDialog = true;
        // The promise here stays pending until the modal assigns a value, then continues.
        const userChoice = await new Promise(resolve => {
          this.unsavedChangesModalOutcome = resolve;
        });

        // Discard is handled by the default case
        // If the navigation proceeds, state is discarded automatically
        if (userChoice === cancel) {
          this.showUnsavedChangesDialog = false;
          return !preventNavigation;
        }
        if (userChoice === save) {
          await this.handleSave();
        }
      }

      this.showUnsavedChangesDialog = false;
      return preventNavigation;
    },

    openHierarchyCreateDialog({ level }) {
      this.mode = dialogModes.create;
      this.level = level;
      this.isHierarchyDialogOpen = true;
    },

    openHierarchyUpdateDialog({ level }) {
      this.mode = dialogModes.edit;
      this.level = level;
      this.isHierarchyDialogOpen = true;
    },

    openHierarchyDeleteDialog({ level }) {
      this.level = level;
      this.isHierarchyDeleteDialogOpen = true;
    },

    closeHierarchyDialog() {
      this.isHierarchyDialogOpen = false;
    },

    closeHierarchyDeleteDialog() {
      this.isHierarchyDeleteDialogOpen = false;
      this.deleteDialogToggle = !this.deleteDialogToggle;
    },

    checkConsistentPgAg(node) {
      const rowKey = node.id;

      // get value from current state diff but if it doesn't exist, get it from the original value

      const currentAgEditValue = get(
        this.currentStateDiff[rowKey],
        'architectureGroup',
        get(node.data, 'architectureGroup')
      );
      const currentPgEditValue = get(
        this.currentStateDiff[rowKey],
        'pricingGroup',
        get(node.data, 'pricingGroup')
      );

      const bothUnassigned = isNil(currentAgEditValue) && isNil(currentPgEditValue);
      const bothAssigned = !isNil(currentAgEditValue) && !isNil(currentPgEditValue);

      return bothUnassigned || bothAssigned;
    },

    getKey(product) {
      if (!product) {
        return null;
      }

      // TODO unify cases when productKey in data object in the root
      const productItem = product.data ? product.data : product;

      const { productKey, toolStoreGroupKey } = productItem;
      return `${productKey}::${toolStoreGroupKey}`;
    },

    getCellKey(params, col = null) {
      // This is used to generate a unique cell id based on row.
      // It's used for selecting specific cells - it doesn't reuse getKey because
      // getKey uses :: which is not a valid CSS selector
      const { productKey, toolStoreGroupKey } = params.data;
      const colId = col || params.colDef.colId;

      return this.zonesEnabled
        ? `id-pkey-${productKey}-tsgk-${toolStoreGroupKey}-${colId}`
        : `id-pkey-${productKey}-tsgk-${colId}`;
    },

    tooltipValueGetter(params) {
      const valueFormatter = params.colDef.valueFormatter;
      const rowNodeKey = this.getKey(params.data);
      const { colId } = params.colDef;
      const originalValuePath = `${rowNodeKey}.${colId}`;

      const errors = this.hasInvalidValue(params);
      const noEditsExist = !has(this.originalValueTracker, originalValuePath);

      if (noEditsExist && !errors) {
        // There are no errors and no edits, tooltip shouldn't display
        return '';
      }

      const originalValue = get(this.originalValueTracker, originalValuePath);

      let formattedOriginalValue = valueFormatter
        ? valueFormatter({ value: originalValue }) || originalValue
        : originalValue;

      if (['pricingGroup', 'architectureGroup'].includes(colId)) {
        // These columns use refData to format, not valueFormatter
        // so we use the refData object to turn the raw key to the name, like the grid does..
        formattedOriginalValue = this.hierarchyIdToNameMap[originalValue];
      }

      const currentValue = get(params.data, params.colDef.field);

      let formattedCurrentValue;
      if (!isNil(currentValue))
        formattedCurrentValue = valueFormatter
          ? valueFormatter({ value: currentValue })
          : currentValue;
      if (formattedCurrentValue === formattedOriginalValue || originalValue === currentValue) {
        return { errors };
      }

      const previousValueText = formattedOriginalValue
        ? `${this.$t(`inputs.previousValue`)}: ${formattedOriginalValue || '-'}`
        : '';

      // For new columns, they have no errors but also no previous value
      // Tooltip now tells them it's a new value
      const newEdit = previousValueText === '' && !errors;
      if (newEdit) {
        return {
          previousValueText: this.$t('inputs.noPreviousValue'),
          errors,
        };
      }

      return {
        previousValueText,
        errors,
      };
    },

    async setFilterRules(filterRules) {
      this.setSelectedFilter({ filterName: 'retailAttributesFilter', filterValue: filterRules });
      const formattedFilters = formatFilters({
        where: this.retailAttributesFilter,
        rootState: this.$store.state,
        ignoreHierarchyLevel: false,
        hierarchyByParentMap: this.hierarchyByParent,
      });
      this.selectedFilterParams = formattedFilters;

      // Prevent mainbanner filter along with other tsg filters
      const tsgFilters = get(this.selectedFilterParams, 'toolStoreGroupKey.$in', []);
      if (this.zonesEnabled && tsgFilters.includes(this.mainTsgKey) && tsgFilters.length > 1) {
        this.invalidFilters = true;
      } else {
        this.invalidFilters = false;
      }

      // Show warning message if filter used without store group
      if (this.zonesEnabled && tsgFilters.length === 0) {
        this.mainBannerFilterWarning = true;
      } else {
        this.mainBannerFilterWarning = false;
      }

      // trigger load
      await this.fetchGridData();
    },

    async fetchInputsData({ filter = null } = {}) {
      let inputs = [];
      try {
        const params = {
          where: filter,
        };

        // If no store-group filter applied, we only return main banner products
        if (this.zonesEnabled && !params.where.toolStoreGroupKey) {
          params.where.toolStoreGroupKey = this.mainTsgKey;
        }

        const suffix = this.routeSuffix ? `/${this.routeSuffix}` : '';
        const { data } = await axios.get(
          `/api/inputs/workpackage/${this.selectedWorkpackage._id}${suffix}`,
          { params }
        );
        inputs = data;
      } catch (e) {
        console.error(e);
      }

      return inputs;
    },

    async fetchGridData() {
      if (this.invalidFilters) return;

      const productsData = await this.fetchInputsData({ filter: this.selectedFilterParams });
      this.numInputsReturned = size(productsData);

      // Grid might not be ready when data is here. If so, wait.
      if (!this.gridApi) {
        await new Promise(resolve => {
          this.gridReadyPromise = resolve;
        });
      }
      this.setGridData(productsData);
      if (!isEmpty(productsData)) {
        this.gridApi.hideOverlay();
      } else {
        this.gridApi.showNoRowsOverlay();
      }
    },

    setGridData(data) {
      // This is the only place we set the grid data. It ensures we create the required backups
      // and diff structures for comparing data.

      // Backup the array of data that's being loaded - we use this for resetting to this state
      this.$options.uneditedProducts = data;
      this.productsByStoreGroupKey = this.$options.uneditedProducts.reduce((acc, product) => {
        if (!acc[product.toolStoreGroupKey]) {
          acc[product.toolStoreGroupKey] = new Set();
        }
        acc[product.toolStoreGroupKey].add(product.productKey);
        return acc;
      }, {});

      // Finding the isInMainbanner attribute key for the current grid data.
      const isInMainbannerAttribute = this.attributeMetadata.find(attr => {
        return attr.displayDescription === this.tooldata.isInMainBannerAttributeName;
      });
      this.isInMainBannerAttributeKey = get(isInMainbannerAttribute, 'attributeKey', '');

      // All diffs have either been saved or discarded - re-initialise the diff.
      this.currentStateDiff = {};
      this.originalValueTracker = {};

      this.gridApi.setGridOption('rowData', data);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.calculateDataTableHeight();
      this.gridApi.showLoadingOverlay();
      this.columnApi = params.columnApi;

      // Grid now ready to be used.
      if (this.gridReadyPromise) {
        this.gridReadyPromise(true);
      }
    },

    onFirstDataRendered() {
      this.gridHasRenderedData = true;
    },

    storegroupSort(valueA, valueB) {
      const mappedA = this.storeGroupsKeyToDescription[valueA];
      const mappedB = this.storeGroupsKeyToDescription[valueB];

      if (mappedA === mappedB) return 0;
      return mappedA > mappedB ? 1 : -1;
    },

    hierarchySort(valueA, valueB) {
      const mappedA = this.hierarchyIdToNameMap[valueA];
      const mappedB = this.hierarchyIdToNameMap[valueB];

      if (mappedA === mappedB) return 0;
      return mappedA > mappedB ? 1 : -1;
    },

    async setupHierarchy() {
      const hierarchy = await this.fetchHierarchy();
      this.hierarchy = hierarchy;

      this.hierarchyByLevel = groupBy(hierarchy, 'level');
      this.hierarchyByParent = this.createHierarchyTree(hierarchy);
      this.hierarchyByKey = keyBy(hierarchy, 'levelEntryKey');
    },

    async setupStoregroups() {
      if (!this.zonesEnabled) return;

      const storegroups = await this.fetchStoregroups();
      this.storegroups = storegroups;
      this.storeGroupsKeyToDescription = this.storegroups.reduce(
        (sgMap, sg) => set(sgMap, sg.toolStoreGroupKey, sg.toolStoreGroupDescription),
        {}
      );

      // Should only run at grid creation
      if (isNil(this.selectedStoregroup)) {
        const defaultStoregroup = this.storegroups.find(sg =>
          isNull(sg.parentPricingStoreGroupKey)
        );
        this.mainTsgKey = defaultStoregroup.toolStoreGroupKey;
        this.selectedStoregroup = defaultStoregroup;
      }

      this.storegroupsByKey = keyBy(storegroups, 'toolStoreGroupKey');
    },

    async loadInputsGridData() {
      await Promise.all([this.setupHierarchy(), this.setupStoregroups()]);
    },

    // hierarchy may be flat string in grid or object with levelEntryDescription in hierarchy field.
    getHierarchyDescription(v) {
      return has(v, 'levelEntryDescription') ? v.levelEntryDescription : v;
    },

    discardChanges() {
      const updates = map(this.originalValueTracker, (previousValues, rowKey) => {
        const row = this.gridApi.getRowNode(rowKey);
        const hierarchy = { ...row.data.hierarchy };

        ['pricingGroup', 'architectureGroup'].forEach(colId => {
          if (has(previousValues, colId)) {
            const coercedPreviousHierarchyValue = isUndefined(previousValues[colId])
              ? null
              : previousValues[colId];
            const hierarchyLevel = hierarchyLevels[`${colId}Level`];
            hierarchy[hierarchyLevel].levelEntryDescription = this.getHierarchyDescription(
              coercedPreviousHierarchyValue
            );
          }
        });

        // flatten previous value :: keys
        const flattenedPreviousValues = reduce(
          previousValues,
          (acc, value, key) => {
            // undefined will not survive lodash merge, but null will
            const coercedValue = isUndefined(value) ? null : value;
            if (key.includes('::')) {
              // replace all :: with . and set nested key
              // e.g. competitor::feed:123::price sets {competitor: {feed: {123: {price: $value}}}}
              // need setWith because set treats integer keys as index to array.
              const flatKey = key.replace(/::/g, '.');
              setWith(acc, flatKey, coercedValue, Object);
            } else {
              acc[key] = coercedValue;
            }
            return acc;
          },
          {}
        );

        const updateData = merge({ ...row.data }, flattenedPreviousValues);
        updateData.hierarchy = hierarchy;

        return updateData;
      });

      this.gridApi.showLoadingOverlay();
      // There can't be any edited products anymore, so turn off that filter
      this.turnOffFilteredRows();
      this.gridApi.applyTransaction({ update: updates });
      this.originalValueTracker = {};
      this.currentStateDiff = {};
      this.invalidRows = {};
      this.gridApi.refreshCells();
      this.gridApi.hideOverlay();
    },

    createHierarchyTree(hierarchies) {
      // We need an obj that provides { parentId: [...allChildrenOfParent ]}
      // This is needed for updating pg and ag.

      const childrenLookup = {};

      each(hierarchies, hierarchy => {
        const parentId = hierarchy.parentId;
        const existingEntry = childrenLookup[parentId];
        if (!existingEntry) set(childrenLookup, parentId, []);
        childrenLookup[parentId].push(hierarchy);
      });

      return childrenLookup;
    },

    async downloadInputs() {
      if (this.exportAction) await this.exportAction();
      else {
        console.info('No export action provided');
        const columnsToExport = [];

        // Exclude the dropdown marker
        this.columnApi.getColumns().forEach(col => {
          if (col.colId === 'expansion-chevron') return;

          columnsToExport.push(col.colId);
        });

        this.gridApi.exportDataAsExcel({
          skipColumnGroupHeaders: true,
          fileName: 'attribute-editor',
          columnKeys: columnsToExport,
        });
      }
    },

    hasUnsavedUpdates() {
      return size(this.currentStateDiff);
    },

    collapseStoregroups() {
      if (!isEmpty(this.expandedSgProducts)) {
        this.gridApi.applyTransaction({
          remove: this.expandedSgProducts,
        });
        each(this.expandedSgProducts, p => {
          delete this.originalValueTracker[this.getKey(p)];
        });
        this.expandedSgProducts = [];
      }
      this.lastExpandedRowKey = null;
    },

    externalFilterChanged() {
      this.gridApi.onFilterChanged();
    },

    isExternalFilterPresent() {
      return !isNil(this.externalFilterSelection);
    },

    doesExternalFilterPass(node) {
      return this.filterRows(node);
    },

    // TODO: fix me
    turnOffFilteredRows() {
      this.filteredRows = {};
      this.gridApi.onFilterChanged();
    },

    async fetchStoregroups() {
      const [err, data] = await to(
        axios.get(
          `/api/store-group-relationships/workpackage/${
            this.selectedWorkpackage._id
          }/workpackage-with-parent`
        )
      );
      if (err) console.error(err);

      return data.data;
    },

    async init() {
      this.dataLoading = true;

      if (this.architectureSubGroupSplittingAttributesEnabled) {
        // scenarios are scoped to pricing groups.
        // need to fetch every candidate scenario in workpackage to generate arch sub-group split attr column
        // see candidateScenariosWithSubGroupSplittingAttributes
        await this.fetchScenarioMetadata();
      }

      await this.loadInputsGridData();
      this.selectedFilterParams = formatFilters({
        where: this.retailAttributesFilter,
        rootState: this.$store.state,
        ignoreHierarchyLevel: false,
        hierarchyByParentMap: this.hierarchyByParent,
      });
      await this.fetchGridData();

      this.gridApi.autoSizeAllColumns();
      this.checkIfProductKeyIsString();
      this.gridApi.applyColumnState({
        state: [
          {
            colId: 'productKeyDisplay',
            sort: 'asc',
          },
        ],
      });
      this.dataLoading = false;
    },

    trackDiff(params) {
      const rowNodeKey = this.getKey(params.data);
      const { colId } = params.colDef;
      const rowNode = params.node;
      const { data } = params;

      const currentValue = params.colDef.valueParser
        ? params.colDef.valueParser(params)
        : get(data, params.colDef.field);
      const previousValue = params.oldValue;

      if (colId === 'pricingGroup') {
        // If you've unassigned the PG, unassign the AG too and exit
        const pgWasUnassigned = isNil(currentValue);
        if (pgWasUnassigned) {
          // PG is null so AG must be null too.
          rowNode.setDataValue('architectureGroup', null);
        } else {
          // case 1: pg is back to original value, so give back original ag value
          const originalPGKey = get(this.originalValueTracker[rowNodeKey], 'pricingGroup');
          if (currentValue === originalPGKey) {
            const originalAGKey = this.originalValueTracker[rowNodeKey].architectureGroup;
            rowNode.setDataValue('architectureGroup', originalAGKey);
          } else {
            // case 2: pg has changed value, pick first available ag value in new scope
            const newAgOptions = this.hierarchyByParent[currentValue];

            // If there are AGs defined, use the first one.
            // Otherwise, there is no AG so it must be unassigned.
            if (size(newAgOptions)) {
              const firstOption = newAgOptions[0].levelEntryKey;

              // Need to use node.setDataValue to trigger update, refresh and diff tracking.
              rowNode.setDataValue('architectureGroup', firstOption);
            } else {
              rowNode.setDataValue('architectureGroup', null);
            }
          }
        }
      }
      // Cascade changes to sg rows and then update the diff on mainbanner row
      this.cascadeChangesToChildren(params);
      this.updateDiff({ rowNodeKey, colId, currentValue, previousValue, isCascade: false });

      // Some columns have dependencies. If A is edited, B may now be invalid.
      // To capture this, we declare dependentColumns. If a column is edited then
      // we run the validations for the corresponding dependent col in the same row.
      const dependentColumns = get(
        { ...this.dependentColumns, ...this.additionalDependentColumns },
        colId,
        []
      );
      const columnsToValidate = [colId, ...dependentColumns];

      columnsToValidate.forEach(validationColId => {
        this.trackValidations({ rowNodeKey, colId: validationColId });
      });

      // TODO investigate why we need manually trigger change detection for visible cells
      // for some reason ag-grid@31 doesn't recalculate cellClassRules after cellValueChanged
      // TODO: Make this row-specific
      this.gridApi.refreshCells({ rowNodes: [rowNode] });
    },

    trackValidations({ rowNodeKey, colId } = {}) {
      const node = this.gridApi.getRowNode(rowNodeKey);
      const column = this.gridApi.getColumn(colId);
      const field = get(column.colDef, 'field');
      const currentValue = get(node.data, field);

      const validations = get(this.allColumnValidations, colId);
      const parameters = {
        value: currentValue,
        node,
        column,
      };

      const errorMessages = [];
      if (validations) {
        each(validations, validation => {
          if (!validation.test(parameters)) {
            errorMessages.push(validation.message);
          }
        });
      }

      const validationsFailed = errorMessages.length;
      const errorAlreadyExists = get(this.invalidRows[rowNodeKey], colId);

      if (validationsFailed) {
        // Validations failed - create object and set this columns entry to be current errors
        if (!this.invalidRows[rowNodeKey]) {
          this.$set(this.invalidRows, rowNodeKey, {});
        }

        this.$set(this.invalidRows[rowNodeKey], colId, errorMessages);
      } else if (errorAlreadyExists && !validationsFailed) {
        // Validations succeeded - delete this column entry
        // If no row validations remain, delete whole row
        this.$delete(this.invalidRows[rowNodeKey], colId);
        if (isEmpty(this.invalidRows[rowNodeKey])) {
          // If no row edits remain, remove entire object.
          this.$delete(this.invalidRows, rowNodeKey);
        }
      }
    },

    debouncedApplyTransactions: debounce(function(params) {
      params.api.applyTransaction({ update: Object.values(this.transactionsToApply) });
      this.transactionsToApply = {};
    }, 100),

    cascadeChangesToChildren(params) {
      if (!this.zonesEnabled) return;

      if (params.node.allChildrenCount) {
        const { colId, field } = params.colDef;
        const parentRowNodeKey = this.getKey(params.data);
        const previousParentValue =
          get(this.originalValueTracker, `${parentRowNodeKey}.${colId}`) || params.oldValue;
        params.node.allLeafChildren.forEach(node => {
          const childRowNodeKey = this.getKey(node.data);
          // No need to re-apply changes to the parent node
          if (parentRowNodeKey === childRowNodeKey) return;
          const previousChildValue =
            get(this.originalValueTracker, `${childRowNodeKey}.${colId}`) || get(node.data, field);
          const isSameAsPreviousParentValue =
            previousChildValue === previousParentValue ||
            (isNil(previousChildValue) && isNil(previousParentValue));

          // Cascade changes if previous values match and no specific values were added to the sg row
          if (
            isSameAsPreviousParentValue &&
            isUndefined(get(this.currentStateDiff, `${childRowNodeKey}.${colId}`))
          ) {
            /**
             * Using transactionsToApply to keep track of rows we need to update. If multiple
             * cells in a row need to be updated we just update the already stored object
             * in transactionsToApply and then apply all updates when finished creating the
             * update lookup.
             */
            if (this.transactionsToApply[node.id]) {
              set(this.transactionsToApply[node.id], field, params.newValue);
            } else {
              const update = cloneDeep(node.data);
              update.id = node.id;
              set(update, field, params.newValue);
              this.transactionsToApply[node.id] = update;
              this.debouncedApplyTransactions(params);
            }

            this.updateDiff({
              rowNodeKey: childRowNodeKey,
              colId,
              currentValue: params.newValue,
              previousValue: params.oldValue,
              isCascade: true,
            });
          }
        });
      }
    },

    convertHierarchiesForSaving() {
      const hierarchyUpdates = {};
      const modifiedArchitectureGroups = new Set();
      // includes pgs that are parents on modifiedAgs
      const modifiedPricingGroups = new Set();

      // ensure previous pg/ags get recalculated
      each(this.originalValueTracker, product => {
        const originalPg = get(product, 'pricingGroup');
        if (!isEmpty(originalPg)) modifiedPricingGroups.add(originalPg);

        const originalAg = get(product, 'architectureGroup');
        if (!isEmpty(originalAg)) {
          modifiedArchitectureGroups.add(originalAg);
          // parent pg of original ag needs to be recalculated
          modifiedPricingGroups.add(this.getHierarchyParentId(originalAg));
        }
      });

      // Convert hierarchy updates for save structure
      // First level keys by product should be productKey::tsgKey
      // Second level keys by hierarchy should be hierarchy::pgLevelKey or hierarchy::agLevelKey
      // Note that we only update hierarchies at the mainbanner level
      each(this.currentStateDiff, (productUpdate, rowKey) => {
        const row = this.gridApi.getRowNode(rowKey);
        if (has(productUpdate, 'pricingGroup')) {
          const newPgValue = {
            ...pick(this.hierarchyByKey[productUpdate.pricingGroup], [
              'levelEntryKey',
              'level',
              'levelEntryDescription',
            ]),
            productKeyDisplay: row.data.productKeyDisplay,
          };
          set(hierarchyUpdates, `${rowKey}.hierarchy::${pricingGroupLevel}`, newPgValue);
          modifiedPricingGroups.add(get(newPgValue, 'levelEntryKey'));
          delete productUpdate.pricingGroup;
        }
        if (has(productUpdate, 'architectureGroup')) {
          const newAgValue = {
            ...pick(this.hierarchyByKey[productUpdate.architectureGroup], [
              'levelEntryKey',
              'level',
              'levelEntryDescription',
            ]),
            productKeyDisplay: row.data.productKeyDisplay,
          };
          set(hierarchyUpdates, `${rowKey}.hierarchy::${architectureGroupLevel}`, newAgValue);

          // parent pg of new ag needs to be recalculated
          modifiedPricingGroups.add(this.getHierarchyParentId(get(newAgValue, 'levelEntryKey')));

          // If we are setting it to null then we don't need to calculate the new one as it is null
          if (productUpdate.architectureGroup) {
            modifiedArchitectureGroups.add(get(newAgValue, 'levelEntryKey'));
          }

          // If we are setting from null then we don't need to calculate the old one as it is null
          if (get(this.originalValueTracker[rowKey], 'architectureGroup.levelEntryKey')) {
            modifiedArchitectureGroups.add(
              get(this.originalValueTracker[rowKey], 'architectureGroup.levelEntryKey')
            );
          }

          delete productUpdate.architectureGroup;
        }

        if (isEmpty(productUpdate)) {
          // If no row edits remain, remove rowKey from updates.
          this.$delete(this.currentStateDiff, rowKey);
        }
      });

      this.pgsToRecalc = Array.from(modifiedPricingGroups);
      this.agsToRecalc = Array.from(modifiedArchitectureGroups).map(agKey => {
        return {
          levelEntryKey: agKey,
          parentId: this.getHierarchyParentId(agKey),
        };
      });

      return hierarchyUpdates;
    },

    /**
     * Converts updates for saving
     * This function puts together new values from currentStateDiff along with
     * old values from originalValueTracker in the structure expected by the BE
     * Example: {"81111::1": {"attributeKey1": {newValue: 1, originalValue: 10} }}
     */
    convertUpdatesForSaving() {
      const updates = {};
      Object.keys(this.currentStateDiff)
        .sort()
        .forEach(rowKey => {
          each(this.currentStateDiff[rowKey], (newValue, colId) => {
            // pg and ag have are saved separately from the rest of the updates
            if (['pricingGroup', 'architectureGroup'].includes(colId)) return;

            const originalValue = get(this.originalValueTracker, `${rowKey}.${colId}`);
            set(updates, `${rowKey}.${colId}.newValue`, newValue);
            set(updates, `${rowKey}.${colId}.originalValue`, originalValue);
          });
        });

      return updates;
    },

    async handleSave() {
      this.gridIsSaveable = false;
      this.gridApi.showLoadingOverlay();

      await Promise.all([
        this.updateProductHierarchies(),
        this.saveChanges({
          updates: this.convertUpdatesForSaving(),
          originalValueTracker: this.originalValueTracker,
          mainTsgKey: this.mainTsgKey,
        }),
      ]);

      this.collapseStoregroups();
      this.resolveGridPostSave();
      this.gridApi.hideOverlay();

      await this.postSaveAction();
      await Promise.all([this.recalculatePricingGroups(), this.recalculateArchitectureDrivers()]);

      this.gridIsSaveable = true;
    },

    getHierarchyObject({ rowNodeKey, hierarchyLevel }) {
      const hierarchy = this.gridApi.getRowNode(rowNodeKey).data.hierarchy;
      return hierarchy[hierarchyLevel];
    },

    getOriginalValue({ editPath, previousValue, originalValueTracker }) {
      const valueAtPath = get(originalValueTracker, editPath);
      // required to return originalValue as undefined if it's explicitly set
      return has(originalValueTracker, editPath) ? valueAtPath : previousValue;
    },

    /**
     * Track updates on the current rowNodeKey
     * Updates currentStateDiff with new values for rows that would get sent to BE after saving
     * Updates originalValueTracker with previous values in case updates get reverted
     */
    updateDiff({ rowNodeKey, colId, currentValue, previousValue, isCascade }) {
      const editPath = `${rowNodeKey}.${colId}`;
      const originalValue = this.getOriginalValue({
        editPath,
        previousValue,
        originalValueTracker: this.originalValueTracker,
      });
      const previousEditedValue = get(this.currentStateDiff, editPath);

      // if no previous edited values, we consider it a new edit
      const newEdit = isUndefined(previousEditedValue);
      // if original value is equal to new value, we consider it a removal
      const editRemoved = isEqual(originalValue, currentValue);
      // if previous edited value is different to new value, we consider it an update
      const editUpdated = !editRemoved && !isEqual(previousEditedValue, currentValue);

      if (newEdit) {
        if (!isCascade) {
          // prevent currentStateDiff from updating while cascading
          this.setStateTrackerValue({
            tracker: this.currentStateDiff,
            rowNodeKey,
            colId,
            value: currentValue,
          });
        }

        this.setStateTrackerValue({
          tracker: this.originalValueTracker,
          rowNodeKey,
          colId,
          value: originalValue,
        });
      } else if (editUpdated) {
        // Existing edit updated to a new value
        this.setStateTrackerValue({
          tracker: this.currentStateDiff,
          rowNodeKey,
          colId,
          value: currentValue,
        });
      } else if (editRemoved) {
        // Remove edit if it resets value to original
        this.deleteStateTrackerValue({
          tracker: this.currentStateDiff,
          rowNodeKey,
          colId,
        });

        this.deleteStateTrackerValue({
          tracker: this.originalValueTracker,
          rowNodeKey,
          colId,
        });
      }
    },

    setStateTrackerValue({ tracker, rowNodeKey, colId, value }) {
      if (!tracker[rowNodeKey]) {
        this.$set(tracker, rowNodeKey, {});
      }

      this.$set(tracker[rowNodeKey], colId, value);
    },

    deleteStateTrackerValue({ tracker, rowNodeKey, colId }) {
      const editPath = `${rowNodeKey}.${colId}`;
      if (has(tracker, editPath)) {
        this.$delete(tracker[rowNodeKey], colId);
      }

      // If no row edits remain, remove entire object.
      if (isEmpty(tracker[rowNodeKey])) {
        this.$delete(tracker, rowNodeKey);
      }
    },

    getParent(params) {
      // For a nonmainbanner row, returns their mainbanner version
      if (!this.zonesEnabled) {
        console.warn('getParent called in non-zones environment.');
        return null;
      }

      const { productKey } = params.data;
      const parentKey = this.getKey({ data: { productKey, toolStoreGroupKey: this.mainTsgKey } });
      const parentRowNode = this.gridApi.getRowNode(parentKey);

      return { parentKey, parentRowNode };
    },

    hasInvalidValue(params) {
      const rowNodeKey = this.getKey(params.data);
      const { colId, field } = params.colDef;

      if (isUndefined(get(this.allColumnValidations, colId))) {
        // Don't try to validate columns without validations
        return;
      }

      const errors = get(this.invalidRows, [rowNodeKey, colId]);

      // If not mainbanner, check if mainbanner has errors and you have the same value
      // if so, you also have errors :(
      let parentErrors;
      // this needs to handle the case where we're not mainbanner but also not grouped.
      if (!this.isMainbanner(params.data.toolStoreGroupKey)) {
        const { parentKey, parentRowNode } = this.getParent(params);

        const parentValue = get(parentRowNode, `data.${field}`);
        if (params.value === parentValue) {
          parentErrors = get(this.invalidRows, [parentKey, colId]);
        }
      }

      return errors || parentErrors;
    },

    hasDiff(params) {
      const rowNodeKey = this.getKey(params.data);
      const { field, colId } = params.colDef;
      const originalValuePath = `${rowNodeKey}.${colId}`;
      if (!has(this.originalValueTracker, originalValuePath)) {
        return false;
      }
      const currentValue = get(params.data, field, null);
      const originalValue = get(this.originalValueTracker, originalValuePath, null);

      const didValueChange = !isEqual(currentValue, originalValue);

      return didValueChange;
    },

    async resolveGridPostSave() {
      // TODO: Potentially make this faster
      // Currently reloads whole grid. If unit is large, that can be slow.
      // Instead, can just apply changes locally and save entire reload.
      // Revisit in PRICE-2200
      await this.init();
    },

    async expandStoregroups(params) {
      if (!this.zonesEnabled) {
        console.error('This should never be called if not using zones');
        return;
      }
      const newExpandedRowKey = this.getKey(params.data);
      const clickedProductKeyDisplay = params.data.productKeyDisplay;

      // If you're about to add the products you just removed, stop and instead don't do that.
      if (this.lastExpandedRowKey === newExpandedRowKey) {
        this.collapseStoregroups();
        return;
      }
      this.collapseStoregroups();

      // Fetch additional store group products
      const sgVersionsOfThisProduct = await this.fetchInputsData({
        filter: {
          productKeyDisplay: clickedProductKeyDisplay,
          toolStoreGroupKey: { $ne: this.mainTsgKey },
        },
      });
      const storegroupProducts = [];
      sgVersionsOfThisProduct.forEach(sgProduct => {
        const sgRowKey = this.getKey(sgProduct);
        // If the parent object has edits, apply them to the child if it has the same saved state
        const existingParentEdits = this.currentStateDiff[newExpandedRowKey];
        const savedParent = this.originalValueTracker[newExpandedRowKey];
        const existingSgEdits = this.currentStateDiff[sgRowKey];
        const updatedSgProduct = cloneDeep(sgProduct);
        // If the child has edits from a previous expansion,
        // apply any changes already made in this session
        each(existingSgEdits, (editedSgValue, field) => {
          const fieldPath = field.split('::').join('.');
          const originalSgValue = get(sgProduct, fieldPath);
          // store original value
          set(this.originalValueTracker, `${sgRowKey}.${field}`, originalSgValue);
          set(updatedSgProduct, fieldPath, editedSgValue);
        });
        // If the child has the same value as the saved parent,
        // apply any changes already made to the parent in this session
        each(existingParentEdits, (editedParentValue, field) => {
          const fieldPath = field.split('::').join('.');
          const originalSgValue = get(sgProduct, fieldPath);
          const existingSgValue = get(updatedSgProduct, fieldPath);
          const savedParentValue = savedParent[field];
          if (existingSgValue === savedParentValue && isUndefined(get(existingSgEdits, field))) {
            // store original value
            set(this.originalValueTracker, `${sgRowKey}.${field}`, originalSgValue);
            set(updatedSgProduct, fieldPath, editedParentValue);
          }
        });
        storegroupProducts.push(updatedSgProduct);
      });
      this.gridApi.applyTransaction({
        add: storegroupProducts,
      });
      this.expandedSgProducts = storegroupProducts;
      this.lastExpandedRowKey = newExpandedRowKey;
    },

    async updateProductHierarchies() {
      if (!this.editableHierarchies) return;

      const updates = this.convertHierarchiesForSaving();
      if (isEmpty(updates)) return;

      const [errHierarchy, dataHierarchy] = await to(
        axios.post(`/api/inputs/workpackage/${this.selectedWorkpackage._id}/hierarchies`, {
          updates,
        })
      );

      if (errHierarchy) console.error(errHierarchy);

      return dataHierarchy;
    },

    hierachyfilter() {
      return {
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                textFormatter: value => {
                  const res = this.hierarchyIdToNameMap[value] || value;
                  return res ? res.toLowerCase() : res;
                },
              },
            },
            {
              filter: 'agSetColumnFilter',
            },
          ],
        },
      };
    },

    async recalculatePricingGroups() {
      if (isEmpty(this.pgsToRecalc)) return;
      const [err, data] = await to(
        this.runEngineForSpecificPricingGroups({ pgKeys: this.pgsToRecalc })
      );

      if (err) console.error(err);

      this.pgsToRecalc = [];
      return data;
    },

    async recalculateArchitectureDrivers() {
      if (isEmpty(this.agsToRecalc)) return;

      const [err, data] = await to(
        axios.post(
          `/api/architecture-drivers/workpackage/${this.selectedWorkpackage._id}/recalculate-many`,
          this.agsToRecalc
        )
      );

      if (err) console.error(err);

      this.agsToRecalc = [];
      return data;
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.main-header th {
  position: relative;
}

#buttons-bar {
  max-width: 20rem;
}

.v-list-item__title {
  font-size: 1.5rem;
}

.v-list-item {
  min-height: 35px;
}

.no-data-slot {
  padding-left: 2.2rem;
}

.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}

.date-attribute-cell {
  position: relative;
}

.diff-background {
  background-color: $ag-grid-edit-background-color;
}

.error-background {
  background-color: $ag-grid-error-background-color;
}

.pale {
  opacity: 0.7;
}

::v-deep {
  .sidebar-toggle.v-icon {
    color: red !important;
    font-family: 'Material Icons Outlined';
    font-feature-settings: 'liga';
    font-size: 20px;
  }
}

.no-data {
  font-size: 1.4rem;
}

.inputs-actions {
  height: 50px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  gap: 16px;
  border-bottom: 1px solid #aeaeae;

  &__btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  .v-text-field__details {
    display: none;
  }
}

.inputs-footer {
  height: 50px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  gap: 16px;
  border-bottom: 1px solid #aeaeae;

  &__row-count {
    font-size: 12px;
  }

  &__msg-container {
    .message {
      color: #ca7c00;
      font-size: 12px;
    }
  }

  &__btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  .v-text-field__details {
    display: none;
  }
}
</style>
